import { Typography } from "@material-ui/core"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { showDescriptionModal } from "../../../redux/slices/showDescriptionModalSlice"
import { getLevelColor } from "../../../utils/racqy-helper"
import { COLORS } from "../../../utils/theme"
import RenderDifficultyExplanations from "../../components/modals/RenderDifficultyExplanations"

export default function SportLevels({
  sport,
  onClick,
  selectedLevels,
  selectedDifficulty,
  multipleSelect,
  errors,
}) {
  const dispatch = useDispatch()
  const levels = [1, 2, 3, 4, 5, 6, 7]

  const isVisible = useSelector(
    state => state.showDescriptionModalSlice.isVisible
  )

  const openDescriptionModal = () => {
    dispatch(showDescriptionModal(true))
  }

  return (
    <div
      style={{
        width: "100%",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <p
          style={{
            padding: "0px",
            margin: "0px",
            fontSize: "12px",
          }}
        >
          Required level to join
        </p>
        <Typography
          style={{
            padding: "0px",
            margin: "0px",
            fontSize: "12px",
            textDecoration: "none",
            color: COLORS.darkGray3,
            cursor: "pointer",
          }}
          onClick={openDescriptionModal}
        >
          Show level description
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {levels.map(level => {
          let levelColor = getLevelColor(level)

          const isSelected = !multipleSelect
            ? selectedLevels[0]?.level == level
            : selectedDifficulty.some(d => d === level)

          let betweenSelected =
            selectedDifficulty &&
            selectedDifficulty[0] < level &&
            selectedDifficulty[1] > level

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "44px",
                marginTop: 15,
                height: "",
                borderRadius: "10px",
                backgroundColor:
                  (isSelected && levelColor.bg) ||
                  (betweenSelected && multipleSelect)
                    ? levelColor.bg
                    : "",
                height: "44px",
                border: `1px solid ${errors ? COLORS.red : COLORS.inputBorder}`,
                marginRight: level < 7 ? 5 : 0,
                position: "relative",
              }}
              key={level}
              onClick={() => {
                onClick({ level })
              }}
            >
              <Typography
                style={{
                  color: isSelected
                    ? levelColor.main
                    : COLORS.darkGray || (betweenSelected && multipleSelect)
                    ? levelColor.main
                    : "",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                {level}
              </Typography>
            </div>
          )
        })}
      </div>
      {isVisible && <RenderDifficultyExplanations sport={sport} />}
    </div>
  )
}

import React, { useEffect, useState } from "react"
import Modal from "@material-ui/core/Modal"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import Backdrop from "@material-ui/core/Backdrop"
import CrossIcon from "../../../../static/images/cross-icon-racqy.svg"
import { COLORS } from "../../../utils/theme"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles, Typography } from "@material-ui/core"
import { showDescriptionModal } from "../../../redux/slices/showDescriptionModalSlice"
import { getDifficultyLevelDescriptions } from "../../../utils/racqy-helper"

const useStyles = makeStyles(theme => ({
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    backgroundColor: COLORS.white,
    border: "0px",
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: 24,
    paddingTop: "30px",
    paddingBottom: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "556px" /* Set a fixed height */,
    overflowY: "auto",
  },
  crossContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "20px",
  },
  crossIcon: {
    height: "16px",
    width: "16px",
    marginRight: "20px",
    cursor: "pointer",
  },
  "&:focus": {
    outline: "none",
  },
}))

export default function RenderDifficultyExplanations({ sport }) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [difficultiesList, setDifficultiesList] = useState([])

  const isVisible = useSelector(
    state => state.showDescriptionModalSlice.isVisible
  )

  useEffect(() => {
    if (sport) {
      const views = []
      for (let difficultyNum = 1; difficultyNum <= 7; difficultyNum++) {
        const difficulty = getDifficultyLevelDescriptions(sport, difficultyNum)
        views.push({ difficultyNum, difficulty })
      }
      setDifficultiesList(views)
    } else {
      return
    }
  }, [sport])

  const closeModal = () => {
    dispatch(showDescriptionModal(false))
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isVisible}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={true}>
        <Box border={"none"} className={classes.box}>
          <div className={classes.crossContainer} onClick={closeModal}>
            <img src={CrossIcon} className={classes.crossIcon} alt="" />
          </div>
          {!sport ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
                Select a sport first!
              </Typography>
            </div>
          ) : (
            difficultiesList.map(item => {
              const { difficulty, difficultyNum } = item
              return (
                <div
                  key={difficultyNum}
                  style={{
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      marginLeft: "30px",
                      color: COLORS.offBlack,
                      fontWeight: "bold",
                    }}
                  >{`${difficultyNum} - ${difficulty[sport]?.title}`}</Typography>
                  <Typography
                    style={{
                      textAlign: "justify",
                      textJustify: "inter-word",
                      padding: "0px 30px 0px 30px",
                    }}
                  >
                    {difficulty[sport]?.description}
                  </Typography>
                </div>
              )
            })
          )}
        </Box>
      </Fade>
    </Modal>
  )
}
